<template>
  <div>
    <notifications group="notification" position="bottom right" />
    <div v-if="pages.length > 0" class="row">
      <div class="col-md-12">
        <div class="card card-custom gutter-b card-stretch">
          <div class="card-header border-0 pt-5">
            <h3 class="card-title">
              <span class="card-label font-weight-bolder text-dark"
                >All Pages</span
              >
            </h3>
            <div class="card-toolbar">
              <router-link
                to="/pages/new"
                class="btn btn-mc font-weight-bolder"
              >
                <span class="svg-icon svg-icon-md svg-icon-white">
                  <inline-svg
                    src="media/svg/icons/Communication/Add-user.svg"
                  />
                </span>
                Add New Page
              </router-link>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table
                class="table table-head-custom table-head-bg table-vertical-center"
              >
                <thead>
                  <tr class="bg-gray-100 text-left">
                    <th style="min-width: 250px" class="pl-7">Page</th>
                    <th style="min-width: 110px">Slug</th>
                    <th style="min-width: 110px" class="pr-7 text-right">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(page, key) in pages" :key="key">
                    <td class="py-4" :class="{ 'border-top-0': key === 0 }">
                      <router-link
                        :to="'/pages/edit/' + page.id"
                        class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >{{ page.title }}</router-link
                      >
                    </td>
                    <td :class="{ 'border-top-0': key === 0 }">
                      <span
                        class="text-dark-75 font-weight-bolder d-block font-size-lg"
                        >{{ page.slug }}</span
                      >
                    </td>
                    <td
                      class="pr-0 text-right"
                      :class="{ 'border-top-0': key === 0 }"
                    >
                      <router-link
                        :to="'/pages/edit/' + page.id"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm mr-3"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg
                            src="media/svg/icons/Communication/Write.svg"
                          />
                        </span>
                      </router-link>
                      <button
                        @click="removePage(page.id)"
                        class="btn btn-icon btn-light btn-hover-light-primary btn-sm"
                      >
                        <span class="svg-icon svg-icon-md">
                          <inline-svg src="media/svg/icons/General/Trash.svg" />
                        </span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12 text-center">
        <b-spinner variant="primary" type="grow"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  name: 'ListPages',
  data() {
    return {
      pages: [],
      view: {
        previous: null,
        first: '?page=1',
        last: null,
        next: null
      },
      isLoaded: false
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'All Companies', route: '/companies/list' }
    ])

    this.getPages('/pages', this.view.first).then(({ data }) => {
      this.pages = [...data['hydra:member']]
      this.isLoaded = true
    })
  },
  methods: {
    async getPages(resource, slug = '') {
      try {
        return await ApiService.get(resource, slug)
      } catch (e) {
        throw new Error(e)
      }
    },
    async deletePage(id) {
      try {
        return await ApiService.delete('/pages/' + id)
      } catch (e) {
        throw new Error(e)
      }
    },
    removePage(id) {
      const confirmation = confirm('You want delete this Page?')
      if (confirmation === false) return

      this.deletePage(id).then(({ status }) => {
        if (status === 204) {
          this.pages = this.pages.filter(company => company.id !== id)
          this.$notify({
            group: 'notification',
            type: 'error',
            title: 'Page success deleted from database'
          })
        }
      })
    }
  }
}
</script>
